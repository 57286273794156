import { useCallback, useEffect, useState } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_ID,
  mesuarementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export default function useFirebaseAuth() {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [isAuthReady, setIsAuthReady] = useState(false);

  const getToken = useCallback(
    async (user_identity: string, room_name: string) => {
      // @ts-ignore
      const app = firebase.app();
      const getTwilioTokenCallable = app.functions('asia-northeast3').httpsCallable('getTwilioToken');

      const r = await getTwilioTokenCallable({
        user_identity,
        room_name,
      });
      console.log('re = ', r);
      return r.data.token;
    },
    [user]
  );

  useEffect(() => {
    firebase.initializeApp(firebaseConfig);
    firebase.auth().onAuthStateChanged(async newUser => {
      const idTokenResult = await newUser?.getIdTokenResult();
      console.log('idTokenResult = ', idTokenResult);
      const coach = idTokenResult?.claims.coach;

      if (newUser && !coach) {
        alert('You need a coach privilege!');
        firebase.auth().signOut();
      }

      console.log('newUser = ', newUser);
      setUser(newUser);
      setIsAuthReady(true);
    });
  }, []);

  const signInWithEmailAndPassword = useCallback((email: string, password: string) => {
    // const provider = new firebase.auth.GoogleAuthProvider();
    // provider.addScope('https://www.googleapis.com/auth/plus.login');

    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(newUser => {
        setUser(newUser.user);
      });
  }, []);

  const signOut = useCallback(() => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
      });
  }, []);

  const getAwhSessions = useCallback(async () => {
    try {
      const colsnap = await firebase
        .firestore()
        .collection('awhSessions')
        .where('coach', '==', user?.uid)
        .where('status', '==', 'active')
        .get();
      return colsnap.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error('Failed to load AwhSessions: ', error);
      return error;
    }
  }, [user]);

  return { user, signInWithEmailAndPassword, getAwhSessions, signOut, isAuthReady, getToken };
}
