import * as React from 'react';
import { useRef } from 'react';
import * as firebase from 'firebase/app';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

// card components
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

import ToggleActivityOnOffButton from '../Buttons/ToggleActivitOnOffButton/ToggleActivityOnOffButton';
import { DialogContent } from '@mui/material';
import ActivityResultsTable from '../ActivityResultsTable/ActivityResultsTable';
// import { Fullscreen } from '@material-ui/icons';

const questions = require('./keywordQuestions.json');

const useStyles = makeStyles({
  noPadding: {
    padding: '0px',
  },
  root: {
    minWidth: '100%',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  listRoot: {
    // width: 800,
    width: '100%',
    maxWidth: '100%',
    maxHeight: 500,
    backgroundColor: 'white',
    overflow: 'scroll',
  },
});

interface Props {
  roomId: string | undefined;
  isOpen: boolean;
  closeFunc: () => void;
}

interface UCProps {
  results: any;
  userPic: string;
  displayName: string;
  activityType: string | null;
}

const { useState, useEffect } = React;
export default function ActivitySelector({ roomId, isOpen, closeFunc }: Props) {
  const classes = useStyles();
  const { isActivityOn, sessionRefData } = useVideoContext();

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState<boolean>(false);
  const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');
  const [loading, setLoading] = useState<boolean>(false);
  const [activityType, setActivityType] = useState<string | null>(null);
  const [foodPics, setFoodPics] = useState<any>([]);
  const [checked, setChecked] = useState([0, 1, 2, 3, 4, 5, 6, 7]);
  const [header, setHeader] = useState('');
  const [question, setQuestion] = useState('');
  const [keywordSelectedHeader, setKeywordSelectedHeader] = useState('Day1');
  const [keywordSelectedQuestion, setKeywordSelectedQuestion] = useState('');
  const [keywordLang, setKeywordLang] = useState<string>('ko');
  const [keywordQuestions, setKeywordQuestions] = useState<string[]>(
    questions[keywordLang][keywordSelectedHeader].questions
  );
  const [selectedSlideNums, setSelectedSlideNums] = useState<number>(0);
  const [activityShowResult, setActivityShowResult] = useState<boolean>(false);

  // data show showResults ->
  const [activityResultData, setActivityResultData] = useState<any[]>([]);
  const [activityResultDataView, setActivityResultDataView] = useState<boolean>(false);

  // table data ->
  const [foodHeaders, setFoodHeaders] = useState<string[]>([]);
  const [foodRows, setFoodRows] = useState<any[]>([]);

  const UserCard = ({ results, userPic, displayName, activityType }: UCProps) =>
    activityType == 'Keyword' ? (
      <Card sx={{ maxWidth: '100%' }}>
        <CardHeader
          avatar={
            <img
              alt={`${displayName}-pic`}
              height={44}
              width={44}
              style={{ borderRadius: 22 }}
              src={userPic ? userPic : '/favicon.png'}
            />
          }
          title={displayName}
        />
        <CardContent>
          <ul>
            {results.map((word: string) => (
              <li style={{ display: 'inline', marginLeft: 10 }}>&#8226;{word}</li>
            ))}
          </ul>
        </CardContent>
      </Card>
    ) : (
      <Card sx={{ maxWidth: '100%' }}>
        <CardHeader
          avatar={
            <img
              alt={`${displayName}-pic`}
              height={44}
              width={44}
              style={{ borderRadius: 22 }}
              src={userPic ? userPic : '/favicon.png'}
            />
          }
          title={displayName}
          subheader={results}
        />
      </Card>
    );
  const activityCleanupHelper = () => {
    setActivityResultDataView(false);
    setActivityResultData([]);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {
    activityCleanupHelper();
  }, [isActivityOn]);

  useEffect(() => {
    if (isActivityOn == false) {
      setShowResultFalse();
      setTimerIsActive(false);
    }
    if (isActivityOn == true) {
      setTimerIsActive(true);
    }
  }, [isActivityOn]);

  useEffect(() => {
    loadFoodPictures();
    return () => {
      // cleanup
    };
  }, [sessionRefData]);

  useEffect(() => {
    updateActivityType();
    if (activityType == 'Keyword') {
      setKeywordSelectedHeader(Object.keys(questions[keywordLang])[0]);
      setKeywordSelectedQuestion(questions[keywordLang][keywordSelectedHeader].questions[0]);
    }
    return () => {
      // cleanup
    };
  }, [activityType, activityResultData]);

  useEffect(() => {
    if (keywordLang && keywordSelectedHeader)
      setKeywordQuestions(questions[keywordLang][keywordSelectedHeader].questions);
  }, [keywordLang, keywordSelectedHeader]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = (activity: string | null) => {
    // console.log('handle Close === ', activity);
    if (activity) {
      setActivityType(activity);
    }
    // setAnchorEl(null);
    setOpen(false);
  };

  const close = () => {
    if (activityType == 'Keyword') {
      setTimer(60);
    } else if (activityType == 'Sleep') {
      setTimer(30);
    } else {
      setTimer(90);
    }
    setOpen(false);
  };

  const loadFoodPictures = async () => {
    // create reference
    const storageRef = firebase.storage().ref('awh/foodPics');
    // console.log('storageRef =', storageRef);

    try {
      setLoading(true);
      // get refereneces for images
      const { items } = await storageRef.listAll();
      // console.log('imageRefs =', items);
      // loop through each image ref and get download url to display
      let foodImgArr: any = [];
      for await (const iterator of items) {
        const imgDownloadURL = await iterator.getDownloadURL();
        // console.log('imgDownloadURL =', imgDownloadURL);
        foodImgArr.push(imgDownloadURL);
      }
      // console.log(foodImgArr)
      setFoodPics(foodImgArr);
      // imageRefs.forEach(img => {
      //   console.log('img =', img);
      // });
    } catch (error) {
      console.error('failed to load pics: ', error);
    } finally {
      setLoading(false);
    }
  };

  const updateActivityType = async () => {
    if (activityType !== null && roomId) {
      const getTime = async (type: string) => {
        return type == 'Sleep'
          ? // setTimer(30)
            30
          : type == 'Keyword'
          ? // setTimer(60)
            60
          : // setTimer(90)
            90;
      };
      const timerToSet = await getTime(activityType);
      setTimer(timerToSet);
      const ref = firebase.firestore().doc(`awhSessions/${roomId}`);
      switch (activityType) {
        case 'Food':
          await ref.update({
            activityType,
            foodPics: checked.map(idx => ({ id: idx, url: foodPics[idx] })),
            activityTimer: timerToSet,
          });
          break;
        default:
          await ref.update({ activityType, activityTimer: timerToSet });
        // , activityTimer: timer
      }
    }
  };

  const setShowResultTrue = async () => {
    const ref = firebase.firestore().doc(`awhSessions/${roomId}`);
    console.log('LOOK HERE TO SEE THE REF STATE', ref);
    const getRef = firebase
      .firestore()
      .doc(`awhSessions/${roomId}`)
      .get();
    console.log(' LOOOK HERE TO SE THE GETREF STATE', getRef);
    await ref.update({
      activityShowResult: true,
    });
    setActivityShowResult(true);
  };

  const setShowResultFalse = async () => {
    const ref = firebase.firestore().doc(`awhSessions/${roomId}`);
    console.log('LOOK HERE TO SEE THE REF STATE', ref);
    const getRef = firebase
      .firestore()
      .doc(`awhSessions/${roomId}`)
      .get();
    console.log(' LOOOK HERE TO SE THE GETREF STATE', getRef);
    await ref.update({
      activityShowResult: false,
    });
    setActivityShowResult(false);
  };

  const activcityOff = async () => {
    const ref = firebase.firestore().doc(`awhSessions/${roomId}`);
    const getRef = firebase
      .firestore()
      .doc(`awhSessions/${roomId}`)
      .get();
    await ref.update({
      isActivityOn: false,
    });
  };

  useEffect(() => {
    activcityOff();
    setShowResultFalse();
  }, []);

  const handleToggle = (value: any) => {
    // console.log('handleToggle: value =', value);
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const updateHeaderAndQuestion = async () => {
    console.log('updateHeaderAndQuestion');
    if (roomId) {
      const ref = firebase.firestore().doc(`awhSessions/${roomId}`);
      try {
        if (activityType === 'Keyword') {
          await ref.update({ header: keywordSelectedHeader, question: keywordSelectedQuestion });
        } else {
          await ref.update({ header, question });
        }
        alert('saved!');
      } catch (error) {
        console.error('Failed to update Header and Question', error);
      }
    }
  };

  const getUserData = async (uid: string) => {
    const publicUsersRef = firebase.firestore().collection('publicUsers');
    const gotUser = await publicUsersRef.doc(uid).get();
    const userData = gotUser.data();
    return userData;
  };

  const showResultsHandler = async () => {
    setFullScreen(!fullScreen);
    try {
      // console.log('showResultsHanlder =', true);
      // 1. makee ref to docoument in collection needed
      const ref = firebase.firestore().doc(`awhSessions/${roomId}`);
      // console.log('ref =', ref);
      // 2. need to reference if it has key showReesults and handle if not there ( optional chaining)
      // const awhSessionDocData = ref
      const getData = await ref.get();
      // console.log('getData =', getData.data());
      const gotData: any = getData.data();
      // console.log('gotData =', gotData);
      const showResults = gotData?.showResults ?? {};
      // 3. set state with data needed to show. need to retrievew users by UID from publicUsers collection.
      // console.log('showResults =', showResults);
      let dataToDisplay: any = [];
      let userPromiseCatcher: any[] = [];
      Object.entries(showResults).map(([uid, data]: any, idx: number) => {
        return userPromiseCatcher.push(getUserData(uid));
      });
      const userPromiseResolver = await Promise.all(userPromiseCatcher);
      console.log('userPromiseResolver =', userPromiseResolver);
      userPromiseResolver.forEach((data, idx) => {
        const { photoURL, displayName } = data;
        const newData = {
          displayName,
          userPic: photoURL,
          results: Object.values(showResults)[idx],
        };
        dataToDisplay.push(newData);
      });
      console.log('dataToDisplay =', dataToDisplay);
      setActivityResultData(dataToDisplay);
      if (activityType == 'Food') {
        console.log('in food results handler');
        const { foodPics } = gotData;
        const headersForFood = foodPics.map(({ url }: any) => {
          return url.substring(url.lastIndexOf('foodPics') + 11, url.lastIndexOf('.png?'));
        });
        setFoodHeaders(['user', ...headersForFood]);
        setFoodRows(dataToDisplay);
      }
      // 4. then display in some data table in another ( modal ? or something ?)
      setActivityResultDataView(!activityResultDataView);
    } catch (error) {
      console.error('showResultsHandler: failure: error =', error);
    }
  };

  const handleLanguage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeywordLang((event.target as HTMLInputElement).value);
    console.log(keywordLang);
  };

  const day1Slides = [8, 11, 25, 28, 32].map(num => String(num));

  const day2Slides = [8, 12, 13, 14, 27, 30, 32, 36].map(num => String(num));

  const day3Slides = ['5', '6a', '6b', '29'];

  const slideNumsArr = [day1Slides, day2Slides, day3Slides];

  // Timer

  const [timer, setTimer] = useState(0);
  const [timeIsActive, setTimerIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const increment: any = useRef(null);
  const [startOrPause, setStartOrPause] = useState('start');
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [timeOutNum, setTimeoutNum] = useState<number>(0);

  useEffect(() => {
    if (activityType == 'Food') {
      setTimeoutNum(90000);
    }
    if (activityType == 'Keyword') {
      setTimeoutNum(60000);
    } else {
      setTimeoutNum(30000);
    }
  }, [activityType]);

  const handleStart = () => {
    setTimerIsActive(true);
    setIsPaused(true);
    increment.current = setInterval(() => {
      setTimer(timer => {
        console.log('inside of setTimer: timer =', timer);
        if (timer == 0) {
          clearInterval(increment.current);
          setTimerIsActive(false);
          setShowResultTrue();
          return 0;
        }
        return timer - 1;
      });
    }, 1000);
  };

  const handlePause = () => {
    clearInterval(increment.current);
    setTimerIsActive(false);
    // setTimer(0);
    // setIsPaused(false);
  };

  useEffect(() => {
    if (isActivityOn == false) {
      setShowResultFalse();
    }
  }, [isActivityOn]);

  return (
    <div>
      <Dialog
        // fullScreen={true}
        fullScreen={fullScreen}
        open={isOpen}
        //TODO make the width wide enough so that the window doesn't change depending on the length of the answers or questions
        onClose={close}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <Button color="primary" variant="text" aria-haspopup="true" onClick={handleClick}>
          <h1>{activityType || 'Please select an activity'}</h1>
        </Button>

        <Menu anchorEl={anchorEl} keepMounted open={open} onClose={() => handleClose(null)}>
          <MenuItem onClick={() => handleClose('Keyword')}>Keyword</MenuItem>
          <MenuItem onClick={() => handleClose('Sleep')}>Sleep</MenuItem>
          <MenuItem onClick={() => handleClose('Food')}>Food</MenuItem>
        </Menu>
        <DialogContent>
          {/* <Box style={{ margin: '1em' }}> */}
          {activityType !== 'Keyword' ? (
            <FormControl component="fieldset" style={{ width: '100%' }}>
              <TextField id="standard-basic" label="Header" fullWidth onChange={e => setHeader(e.target.value)} />
              <TextField id="filled-basic" label="Question" fullWidth onChange={e => setQuestion(e.target.value)} />
            </FormControl>
          ) : (
            // activityType === null || undefined || 'Sleep' ? null : (
            //   <>
            //     <TextField id="standard-basic" label="Header" fullWidth onChange={e => setHeader(e.target.value)} />
            //     <TextField id="filled-basic" label="Question" fullWidth onChange={e => setQuestion(e.target.value)} />
            //   </>
            // )
            <>
              <FormControl component="fieldset">
                <FormLabel component="legend">Language</FormLabel>
                <RadioGroup
                  row
                  aria-label="language"
                  name="controlled-radio-buttons-group"
                  value={keywordLang}
                  onChange={handleLanguage}
                >
                  {/* These needs to be changed to drop down list */}
                  <FormControlLabel value="ko" control={<Radio />} label="Korean" />
                  <FormControlLabel value="en" control={<Radio />} label="English" />
                </RadioGroup>
                <FormLabel component="legend">Day</FormLabel>
                <RadioGroup
                  row
                  name="keywordHeaders"
                  id="keywordHeaders"
                  value={keywordSelectedHeader}
                  onChange={({ target: { value: header } }) => {
                    setSelectedSlideNums(Number(header.substr(header.length - 1)) - 1);
                    setKeywordSelectedHeader(header);
                    // setHeader(e.target.value);
                  }}
                >
                  {Object.keys(questions[keywordLang]).map((header, idx) => {
                    if (idx === 0)
                      return <FormControlLabel key={idx} value={header} label={header} control={<Radio />} />;
                    return <FormControlLabel key={idx} value={header} control={<Radio />} label={header} />;
                  })}
                </RadioGroup>
                <br />
                <FormLabel component="legend">Questions</FormLabel>
                <RadioGroup
                  name="keywordQuestions"
                  id="keywordQuestions"
                  onChange={({ target: { value: question } }) => {
                    setKeywordSelectedQuestion(question);
                  }}
                  value={keywordSelectedQuestion}
                >
                  {keywordQuestions.map((question, idx) => {
                    if (idx === 0)
                      return (
                        <FormControlLabel
                          key={idx}
                          value={question}
                          label={`${slideNumsArr[selectedSlideNums][idx]} ${question}`}
                          control={<Radio />}
                        />
                      );
                    return (
                      <FormControlLabel
                        key={idx}
                        value={question}
                        label={`${slideNumsArr[selectedSlideNums][idx]} ${question}`}
                        control={<Radio />}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </>
          )}

          {/* </Box> */}
        </DialogContent>

        <DialogContent style={{ padding: 0 }}>
          {activityType === 'Food' ? (
            loading ? (
              <CircularProgress />
            ) : (
              <List
                dense
                className={classes.listRoot}
                style={fullScreen ? { flexDirection: 'row' } : { flexDirection: 'column', maxHeight: 200 }}
              >
                {foodPics.map((value: string, idx: number) => {
                  // console.log('foodpics.map: value =', value);
                  const foodType = value.substring(value.lastIndexOf('foodPics') + 11, value.lastIndexOf('.png?'));

                  const labelId = `checkbox-list-secondary-label-${idx}`;
                  return (
                    <ListItem key={idx} button onClick={() => handleToggle(idx)}>
                      <ListItemAvatar>
                        <Avatar alt={value} src={value ? value : '/favicon.png'} />
                      </ListItemAvatar>
                      <ListItemText id={labelId} primary={foodType} />
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge="end"
                          onChange={() => handleToggle(idx)}
                          checked={checked.indexOf(idx) !== -1}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
            )
          ) : null}
        </DialogContent>
        {activityResultDataView && (
          <DialogContent style={{ padding: 0 }}>
            {activityType == 'Food' ? (
              <ActivityResultsTable headers={foodHeaders} rows={foodRows} />
            ) : (
              <div style={{ flexDirection: 'row' }}>
                {activityResultData.map((data, idx) => {
                  const { displayName, userPic, results } = data;
                  console.log('look here to see the data', data);
                  return (
                    <UserCard
                      key={`${displayName}-${idx}`}
                      userPic={userPic}
                      displayName={displayName}
                      results={results}
                      activityType={activityType}
                    />
                  );
                })}
              </div>
            )}
          </DialogContent>
        )}

        {activityType === null || undefined ? null : (
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              size="medium"
              style={{ marginTop: '1em' }}
              onClick={updateHeaderAndQuestion}
            >
              Submit
            </Button>
          </DialogActions>
        )}
        <DialogActions>
          {isActivityOn && timeIsActive && activityShowResult == false ? (
            <div>
              <div>{activityType} activity is running.</div>
              <div>{timer} seconds left</div>
            </div>
          ) : null}
          <ToggleActivityOnOffButton
            disabled={fullScreen}
            extraFunc={() => {
              startOrPause == 'start' ? handleStart() : handlePause();
              setStartOrPause(startOrPause == 'start' ? 'pause' : 'start');

              if (activityResultData.length !== 0) setActivityResultData([]);
            }}
          />
          <Button onClick={showResultsHandler} disabled={!isActivityOn}>
            {fullScreen ? 'Close Results' : 'Show Results'}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => {
              setShowResultTrue();
              // setTimerIsActive(false);
              handlePause();
            }}
          >
            STOP TIMER
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={async () => {
              await updateActivityType();
              closeFunc();
              setShowResultFalse();
              activcityOff();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
