import React, { ChangeEvent, FormEvent, useEffect, useState, useRef } from 'react';
import {
  Typography,
  makeStyles,
  Grid,
  Button,
  Theme,
  Box,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Dialog,
} from '@material-ui/core';
import { useAppState } from '../../../state';
import dayjs from 'dayjs';

import ActivitySelector from '../../ActivitySelector/ActivitySelector';
// toggling json obj of activities from users
// import ToggleActivityButton from '../../Buttons/ToggleActivityButton/ToggleActivityButton';

const daysOfweek = ['일', '월', '화', '수', '목', '금', '토'];

const weekday = require('dayjs/plugin/weekday');
dayjs.extend(weekday);

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  roomSelectButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

interface IRoom {
  title: string;
  id: string;
  coach: string;
  daysOfWeek: number[];
  startTime: string;
  endTime: string;
  members: string[];
}

export default function RoomNameScreen({ name, roomName, setName, setRoomName, handleSubmit }: RoomNameScreenProps) {
  const classes = useStyles();
  const { user, getAwhSessions } = useAppState();
  const [rooms, setRooms] = useState<IRoom[]>([]);
  const appState = useAppState();

  const [open, setOpen] = useState(false);
  const [openTimer, setOpenTimer] = useState(false);

  const [timer, setTimer] = useState(3595);
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const increment: any = useRef(null);
  const [startOrPause, setStartOrPause] = useState('start');

  // console.log('name, roomName= ', name, roomName);

  useEffect(() => {
    // console.log('user from useAppState =', appState);
    const getRooms = async () => {
      const rooms = await getAwhSessions();
      console.log('rooms = ', rooms);
      setRooms(rooms);
    };
    getRooms();
    return () => {
      // cleanup
    };
  }, []);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  const hasUsername = !window.location.search.includes('customIdentity=true') && user?.displayName;

  const selectRoom = (room: IRoom) => setRoomName(room.id);

  const handleStart = () => {
    setIsActive(true);
    setIsPaused(true);
    increment.current = setInterval(() => {
      setTimer(timer => timer - 1);
    }, 1000);
  };
  const handlePause = () => {
    clearInterval(increment.current);
    setIsPaused(false);
  };

  return (
    <>
      <ActivitySelector roomId={rooms[0]?.id} isOpen={open} closeFunc={() => setOpen(false)} />
      <Typography variant="h5" className={classes.gutterBottom}>
        Select a Room
      </Typography>
      <Box style={{ maxHeight: 200, overflow: 'auto' }}>
        <List>
          {rooms.map((room, idx) => (
            <ListItem button key={idx} onClick={() => selectRoom(room)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={room.id === roomName}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': room.id }}
                />
              </ListItemIcon>
              <ListItemText>
                {`${room.title}(${room.daysOfWeek.map(day => daysOfweek[day]).join(',')},${room.startTime})`}
              </ListItemText>
            </ListItem>
          ))}
          {rooms.length === 0 ? (
            <Typography variant="h5" className={classes.gutterBottom}>
              No rooms
            </Typography>
          ) : null}
        </List>
      </Box>
      <form onSubmit={handleSubmit}>
        <Grid container justify="flex-end">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!name || !roomName}
            className={classes.continueButton}
          >
            Continue
          </Button>
          <Button onClick={() => setOpen(true)} color="secondary">
            test func
          </Button>
          <Button onClick={() => setOpenTimer(true)} color="secondary">
            Open Timer
          </Button>
        </Grid>
      </form>
      <Dialog open={openTimer} onClose={() => setOpenTimer(false)}>
        <h1>{timer}</h1>
        <Button
          onClick={async () => {
            startOrPause == 'start' ? handleStart() : handlePause();
            setStartOrPause(startOrPause == 'start' ? 'pause' : 'start');
          }}
        >
          {startOrPause}
        </Button>
        {/* <Button onClick={handlePause}>pause</Button> */}
      </Dialog>
    </>
  );
}
