export const achievementLabels = {
  0: {
    0: 'Healthy eating habits',
    1: 'Physical activity',
    2: 'Healthy sleeping habits',
  },
  1: {
    0: 'Relaxation',
    1: 'Optimistion + hope',
    2: 'Positive attitude',
  },
  2: {
    0: 'Spiritual beliefs + practices',
    1: 'Supportive relationships',
    2: 'Positive attitude',
    3: 'Purpose + meaning in life',
  },
  3: {
    0: 'Keeping the concept of impact in mind, write down a health goal that you want to achieve',
    1: 'IMPACT Action Plan - Questions ----> ANSWERS = 0(no) / 1(yes)',
    2: 'DASS',
  },
};

export const assessmentLabels = [
  '5 reasons to improve health',

  'Biggest reason for wanting to improve health',

  'What is current health goal',

  'Health assessment survey',

  'reasons why you have a healthy lifestyle',
];

export const wk8Labels = {
  0: {
    0: '8-week action plan',
  },
  1: {
    0: 'Modified Goal',
    extra: {
      modifiedGoal: '',
      giveUpReason: '',
      // did oyu achieve your goal true/false?
      attainment: null,
      confidence: 0,
    },
  },
};
