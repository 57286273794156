import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import useScreenShareParticipant from '../../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import ChatIcon from '../../../icons/ChatIcon';

export const ACTIVITY_TEXT = 'User Activities';
export const HIDE_ACTIVITY_RESULT_TEXT = 'Hide Activity Result Screen';
export const DISABLED_TEXT = 'Button is disabled because no activities are logged yet';
export const SHARE_NOT_SUPPORTED_TEXT = 'Screen sharing is not supported with this browser';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      '&[disabled]': {
        color: '#bbb',
        '& svg *': {
          fill: '#bbb',
        },
      },
    },
  })
);

export default function ToggleActivityButton(props: { disabled?: boolean }) {
  const classes = useStyles();
  const screenShareParticipant = useScreenShareParticipant();
  const { toggleActivityView } = useVideoContext();
  const disableUserActivitiesButton = Boolean(screenShareParticipant);
  const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
  const isDisabled = props.disabled || disableUserActivitiesButton || !isScreenShareSupported;

  let tooltipMessage = '';

  if (disableUserActivitiesButton) {
    tooltipMessage = DISABLED_TEXT;
  }

  return (
    <Tooltip
      title={tooltipMessage}
      placement="top"
      PopperProps={{ disablePortal: true }}
      style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
    >
      <span>
        {/* The span element is needed because a disabled button will not emit hover events and we want to display
          a tooltip when screen sharing is disabled */}
        <Button
          disabled={props.disabled}
          className={classes.button}
          onClick={toggleActivityView}
          startIcon={<ChatIcon />}
          data-cy-share-screen
        >
          {ACTIVITY_TEXT}
        </Button>
      </span>
    </Tooltip>
  );
}
