import * as React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

interface Props {
  data: any[];
  type?: string | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: 'inline',
    },
  })
);

export default function AlignItemsList({ data, type = null }: Props) {
  const classes = useStyles();

  const linebreak = () => {
    return <div style={{ flexBasis: '100%', height: 0 }} />;
  };

  return (
    <div className={classes.root}>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
        {' '}
        {Object.values(data)
          .map((answer: any, idx: number) => {
            return (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '49%',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    marginTop: 15,
                    backgroundColor: 'aliceblue',
                  }}
                >
                  {/* left side */}
                  <div
                    style={{
                      width: '20%',
                      height: '100%',
                      backgroundColor: 'ghostwhite',
                      padding: 7,
                    }}
                  >
                    <Typography style={{ fontWeight: 'bold' }}>
                      {Object.keys(data)[idx] == '0' ? 'Overall Goal' : Object.keys(data)[idx] + ': '}
                    </Typography>
                  </div>
                  {/* right side */}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: Array.isArray(answer) && Object.keys(answer).length > 10 ? 'row' : 'column',
                      width: '80%',
                      padding: 7,
                    }}
                  >
                    {answer == '' || answer == null ? (
                      <Typography style={{ fontStyle: 'italic', fontWeight: 'bold' }}>n/a</Typography>
                    ) : typeof answer == 'string' || typeof answer == 'number' ? (
                      <Typography>{answer}</Typography>
                    ) : (
                      // wk8 data
                      <view
                        style={{
                          display: 'flex',
                          flexDirection: answer.length > 6 ? 'row' : 'column',
                          flexWrap: 'wrap',
                        }}
                      >
                        {answer.every((el: any) => el == '') ? (
                          <view style={{ fontWeight: 'bold' }}>{'n/a'}</view>
                        ) : (
                          answer.map((value: any, i: number) => (
                            <view
                              key={String(i)}
                              style={{
                                display: 'flex',
                                flexDirection: answer.length > 6 ? 'column' : 'row',
                                border: answer.length > 6 ? '1px solid black' : undefined,
                              }}
                            >
                              <view style={{ color: 'red' }}>{`${String(i + 1)}: `}</view>
                              <view>{`${JSON.stringify(value?.answer ?? value)}  `}</view>
                            </view>
                          ))
                        )}
                      </view>
                    )}
                  </div>
                </div>
                {idx % 2 == 1 ? () => linebreak() : null}
              </>
            );
          })
          .sort()}
      </div>
    </div>
  );
}
