import * as firebase from 'firebase/app';
import { useState, useCallback, useEffect, useRef } from 'react';
import { LogLevels, Track, Room } from 'twilio-video';
import { useAppState } from '../../../state';
import { ErrorCallback } from '../../../types';

interface MediaStreamTrackPublishOptions {
  name?: string;
  priority: Track.Priority;
  logLevel: LogLevels;
}

export default function useActivityOnOffToggle(room: Room | null, onError: ErrorCallback) {
  const [isActivityOn, setIsActivityOn] = useState(false);
  const [sessionRefData, setSessionRefData] = useState<any>(null);

  console.log('isActivityOn = ', isActivityOn);

  const showActivity = useCallback(
    async (willShow: boolean) => {
      console.log('willShow = ', willShow);
      if (room) {
        try {
          let thingsToUpdate = { isActivityOn: willShow };
          if (willShow === false) {
            thingsToUpdate = {
              ...thingsToUpdate,
              //@ts-ignore
              header: firebase.firestore.FieldValue.delete(),
              question: firebase.firestore.FieldValue.delete(),
              showResults: firebase.firestore.FieldValue.delete(),
            };
          }
          const ref = firebase.firestore().doc(`awhSessions/${room?.name}`);

          await ref.update(thingsToUpdate);
          setIsActivityOn(willShow);
          const sessionData = (await ref.get()).data();
          setSessionRefData(sessionData);
        } catch (error) {
          onError(error);
        }
      }
    },
    [room, onError]
  );

  const toggleActivity = useCallback(() => {
    if (room) {
      !isActivityOn ? showActivity(true) : showActivity(false);
    }
  }, [isActivityOn, showActivity, room]);

  return [isActivityOn, toggleActivity, sessionRefData] as const;
}
