import * as React from 'react';
import { styled, Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import * as firebase from 'firebase/app';

import MenuBar from './components/MenuBar/MenuBar';
import MobileTopMenuBar from './components/MobileTopMenuBar/MobileTopMenuBar';
import PreJoinScreens from './components/PreJoinScreens/PreJoinScreens';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import Room from './components/Room/Room';

import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';

import useVideoContext from './hooks/useVideoContext/useVideoContext';
import { Drawer, Button, Modal } from '@material-ui/core';

import AlignItemsList from './components/AWHComponents/ListItems';

import { achievementLabels, assessmentLabels, wk8Labels } from './utils/AWHLabeling';
import { flexbox } from '@mui/system';

interface UARCardProps {
  img?: any;
  name: string;
  achievement?: any;
  assessment?: any;
  wk8?: any;
}

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
});

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
  overflow: 'hidden',
  paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
  background: 'black',
  [theme.breakpoints.down('sm')]: {
    paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activitiesDrawer: {
      backgroundColor: 'white',
      flex: 1,
    },
    userRowCard: {
      borderWidth: 1,
      borderColor: 'magenta',
      display: 'flex',
      justifyContent: 'space-around',
      width: '100vw',
      alignItems: 'center',
      paddingLeft: 30,
      paddingRight: 30,
    },
    useRowCardPic: {
      height: 44,
      width: 44,
      borderRadius: 22,
    },
    buttonContainer: {
      maxWidth: 400,
    },
    viewHomeworkButtons: {
      minWidth: 200,
    },
    userName: {
      minWidth: 200,
    },
    paper: {
      // Modal
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);
// after mapping through membersToList data
//  finish displaying the modals with the json objects --> if no data-> maybe disabl button
//  add exit button from modal. --> add css plz. maybe use data table from material
//  i think material has some kind of filtering search bar. maybe not need but nice to have is a view all members table ==> extra credit task
//  change default placeholder avatar to gideb logo.
// then once finished lets work on awhSession.showResults display
const { useEffect, useState } = React;
export default function App() {
  const classes = useStyles();
  const roomState = useRoomState();
  const { isActivityViewShown, toggleActivityView, activities } = useVideoContext();

  // state here
  const [membersToList, setMembersToList] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  // const [achievementData, setAchievementData] = useState<any>({});
  // const [assessmentData, setAssessmentData] = useState<any>({});
  // const [wk8Data, setWk8Data] = useState<any>({});
  const [showNoDataError, setShowNoDataError] = useState<boolean>(false);
  const [displayData, setDisplayData] = useState<any>(null);

  function handleClose() {
    setOpen(false);
  }

  const dayLabeler = (num: number) => {
    switch (num) {
      case 0:
        return 'Day1';
      case 1:
        return 'Day2-1';
      case 2:
        return 'Day2-2';
      case 3:
        return 'Day3-1';
      case 4:
        return 'Wk8';
      default:
        return 'Day Err';
    }
  };

  const weekItemLabeler = (weekObj: any) => {
    let out: any = [];
    let tmp = Object.entries(weekObj).sort();
    tmp.map(([key, val]: any) => out.push(`${key}: ${val == '' ? 'n/a' : val}`));
    return out;
  };

  const yesOrNoHandler = (oneOrZero: number) => {
    return oneOrZero == 0 ? 'No' : 'Yes';
  };

  const makeDisplayData = (data: any, labels: any, type: string) => {
    console.log('makeDisplayData: data =', data);
    // console.log('makeDisplayData: labels =', labels)
    let dataToDisplay: any = {};
    if (type == 'achievement') {
      Object.keys(data).map((key: any) => {
        // if (data[key].length == 0) {
        //   // if (data[key].length == 0 || data[key].every((el: any) => el == ''))
        //   dataToDisplay[dayLabeler(Number(key)) + ' ' + labels[key]] = 'n/a';
        //   console.log('!!!', data[key]);
        // } else
        for (const otherKey in data[key]) {
          dataToDisplay[dayLabeler(Number(key)) + ' ' + labels[key][otherKey]] = data[key][otherKey];
        }
      });
    } else if (type == 'assessment') {
      Object.values(data).map((el: any, i: number) => {
        dataToDisplay[`Question${i + 1}: ` + labels[i]] = el;
      });
    } else {
      dataToDisplay[0] = data[0];
      Object.values(data[1]).map((week: any, i: number) => {
        dataToDisplay[`wk${i + 1}`] = weekItemLabeler(week);
      });
    }
    console.log('dataToDisplay =', dataToDisplay);

    setDisplayData(dataToDisplay);
  };

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  const getUser = async (uid: string) => {
    const gotUser = (
      await firebase
        .firestore()
        .collection('publicUsers')
        .doc(uid)
        .get()
    ).data();
    return gotUser;
  };

  const resolveUsers = async (usersArr: any[], homeworkArr: any[]) => {
    try {
      let membersData: any[] = [];
      const promiseResolveUsers = await Promise.all(usersArr);
      for (let i = 0; i < activities.length; i++) {
        const dataToPush = {
          ...promiseResolveUsers[i],
          ...homeworkArr[i],
        };

        membersData.push(dataToPush);
      }
      setMembersToList(membersData);
    } catch (error) {
      console.error('resolveUsers: error =', error);
    }
  };

  useEffect(() => {
    if (roomState === 'connected') {
      let users: any = [];
      let homeworks: any = [];
      try {
        for (const iterator in activities) {
          const { uid, homework } = activities[iterator];
          const gotUser: any = getUser(uid);

          users.push(gotUser);
          homeworks.push(homework);
        }
        console.log('!!!!', homeworks);
        resolveUsers(users, homeworks);
      } catch (error) {
        console.error('App.tsx: useEffect: error =', error);
      }
    }
  }, [activities]);

  const DisplayDataModal = (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'scroll' }}
      >
        <div
          style={{
            width: '80%',
            backgroundColor: 'white',
            padding: 20,
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => handleClose()}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            Close
          </Button>
          <div style={{ marginTop: 40 }} />
          <AlignItemsList data={displayData} />
        </div>
      </Modal>
    </>
  );

  const noDataErrorModal = (
    <>
      <Modal
        open={showNoDataError}
        onClose={() => setShowNoDataError(false)}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <div
          style={{
            width: 200,
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: 30,
          }}
        >
          <div style={{ marginTop: 40 }} />
          <text> no user data </text>
          <div style={{ marginTop: 40 }} />
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setShowNoDataError(false)}
            style={{ alignSelf: 'center', display: 'block' }}
          >
            Close
          </Button>
        </div>
      </Modal>
    </>
  );

  const UserActivityRowCard: React.FC<UARCardProps> = ({
    img = null,
    name,
    achievement = null,
    assessment: assesment = null,
    wk8 = null,
  }) => {
    return (
      <div className={classes.userRowCard}>
        <div>
          {img ? (
            <img src={img} className={classes.useRowCardPic} alt="" />
          ) : (
            <img
              src="https://media.istockphoto.com/photos/businessman-silhouette-as-avatar-or-default-profile-picture-picture-id476085198?s=612x612"
              className={classes.useRowCardPic}
              alt=""
            />
          )}
        </div>
        <div className={classes.userName}>
          <h2>{name}</h2>
        </div>
        <Button
          variant="outlined"
          color="primary"
          className={classes.viewHomeworkButtons}
          onClick={() => {
            if (achievement != null) {
              makeDisplayData(achievement, achievementLabels, 'achievement');
              // setAchievementData(achievement);
              setOpen(true);
            } else setShowNoDataError(true);
          }}
        >
          Achievement
        </Button>
        <Button
          variant="outlined"
          color="primary"
          className={classes.viewHomeworkButtons}
          onClick={() => {
            if (assesment != null) {
              makeDisplayData(assesment, assessmentLabels, 'assessment');
              // setAssessmentData(assesment);
              setOpen(true);
            } else setShowNoDataError(true);
          }}
        >
          Assessment
        </Button>
        <Button
          variant="outlined"
          color="primary"
          className={classes.viewHomeworkButtons}
          onClick={() => {
            if (wk8 != null) {
              makeDisplayData(wk8, wk8Labels, 'wk8');
              // setWk8Data(wk8);
              setOpen(true);
            } else setShowNoDataError(true);
          }}
        >
          Wk8
        </Button>
      </div>
    );
  };

  return (
    <>
      {noDataErrorModal}
      {DisplayDataModal}
      <Container style={{ height }}>
        {roomState === 'disconnected' ? (
          <PreJoinScreens />
        ) : (
          <Main>
            <ReconnectingNotification />
            <MobileTopMenuBar />
            <Room />
            <MenuBar viewUserActivity={activities.length === 0} />
            <Drawer
              anchor="right"
              open={isActivityViewShown}
              onClose={toggleActivityView}
              className={classes.activitiesDrawer}
            >
              <div style={{ marginLeft: 50 }}>
                <h1>Activities</h1>
              </div>
              {membersToList.map((member, idx) => {
                const { displayName: name, DAYSv2 = null, ASSv2 = null, wk8 = null, photoURL: img } = member;
                return (
                  <UserActivityRowCard
                    key={idx}
                    name={name}
                    achievement={DAYSv2}
                    assessment={ASSv2}
                    img={img}
                    wk8={wk8}
                  />
                );
              })}

              <Button
                variant="outlined"
                color="secondary"
                onClick={toggleActivityView}
                style={{ maxWidth: 100, zIndex: 9999, position: 'absolute', top: 20, right: 100 }}
              >
                Close
              </Button>
            </Drawer>
          </Main>
        )}
      </Container>
    </>
  );
}
