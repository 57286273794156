import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import ScreenShareIcon from '../../../icons/ScreenShareIcon';
import Tooltip from '@material-ui/core/Tooltip';

import useScreenShareParticipant from '../../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import ChatIcon from '../../../icons/ChatIcon';
import { isExternalModuleReference } from 'typescript';
// import { useEffect } from 'react-router/node_modules/@types/react';

export const TURN_ON_ACTIVITY_TEXT = 'Turn on Activity';
export const TURN_OFF_ACTIVITY_TEXT = 'Turn off Activity';
export const STOP_SCREEN_SHARE_TEXT = 'Stop Sharing Screen';
export const SHARE_IN_PROGRESS_TEXT = 'Cannot share screen when another user is sharing';
export const SHARE_NOT_SUPPORTED_TEXT = 'Screen sharing is not supported with this browser';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      '&[disabled]': {
        color: '#bbb',
        '& svg *': {
          fill: '#bbb',
        },
      },
    },
  })
);

export default function ToggleActivityOnOffButton(props: {
  // timer: number;
  disabled?: boolean;
  extraFunc?: () => void;
}) {
  const classes = useStyles();
  const { isActivityOn, toggleActivity } = useVideoContext();
  const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
  const isDisabled = props.disabled;
  // const [timer, setTimer] = React.useState(props.timer);
  // let timer: number = props.timer;

  // React.useEffect(() => {
  //   console.log('timer');
  // }, [timer, props.timer]);

  let tooltipMessage = isActivityOn ? TURN_OFF_ACTIVITY_TEXT : TURN_ON_ACTIVITY_TEXT;

  // const startOrStopTimer = () => {
  //   setTimeout(() => {
  //     // timer = timer - 1;
  //     document.getElementById('timerSpan').innerText = timer - 1
  //   }, 1000);
  // };

  return (
    <Tooltip
      title={tooltipMessage}
      placement="top"
      PopperProps={{ disablePortal: true }}
      style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
    >
      <>
        {/* <span id="timerSpan">{timer}</span> */}
        <span>
          {/* The span element is needed because a disabled button will not emit hover events and we want to display
          a tooltip when screen sharing is disabled */}
          <Button
            className={classes.button}
            onClick={() => {
              toggleActivity();
              // startOrStopTimer();
              if (props.extraFunc) {
                props.extraFunc();
              }
            }}
            disabled={isDisabled}
            startIcon={<ChatIcon />}
            data-cy-share-screen
          >
            {isActivityOn ? TURN_OFF_ACTIVITY_TEXT : TURN_ON_ACTIVITY_TEXT}
          </Button>
        </span>
      </>
    </Tooltip>
  );
}
