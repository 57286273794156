import * as firebase from 'firebase/app';
import { useState, useCallback, useEffect, useRef } from 'react';
import { LogLevels, Track, Room } from 'twilio-video';
import { useAppState } from '../../../state';
import { ErrorCallback } from '../../../types';

interface MediaStreamTrackPublishOptions {
  name?: string;
  priority: Track.Priority;
  logLevel: LogLevels;
}

export default function useActivityToggle(room: Room | null, onError: ErrorCallback) {
  const [isActivityShown, setIsActivityShown] = useState(false);
  const [activities, setActivities] = useState<[]>([]);

  // console.log('isActivityShown = ', isActivityShown);

  useEffect(() => {
    if (!room) return;

    const unsubscribe = firebase
      .firestore()
      .collection(`awhSessions/${room?.name}/activities`)
      .onSnapshot(querySnapshot => {
        const _items = querySnapshot.docs.map(doc => ({ uid: doc.id, ...doc.data() }));
        console.log('user activities from useActivityToggle = ', _items);
        // @ts-ignore
        setActivities(_items);
      });
    return () => {
      //cleanup
      unsubscribe();
    };
  }, [room]);

  const showActivity = useCallback(
    async (willShow: boolean) => {
      console.log('willShow = ', willShow);
      if (room) {
        try {
          setIsActivityShown(willShow);
        } catch (error) {
          onError(error);
        }
      }
    },
    [room, onError]
  );

  const toggleActivity = useCallback(() => {
    if (room) {
      !isActivityShown ? showActivity(true) : showActivity(false);
    }
  }, [isActivityShown, showActivity, room]);

  return [isActivityShown, toggleActivity, activities] as const;
}
