import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

interface Props {
  headers: string[];
  rows: any[];
}

export default function ActivityResultsTable({ headers, rows }: Props) {
  console.log('LOOK HERE TO SEE THE ROWS', rows);
  console.log('LOOK HERE TO SEE THE HEADERS', headers);

  // const keys = Object.keys(rows[0].results);
  // console.log(' LOOOK HERE TO SEE THE KEYS', keys);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {headers.map((header: string, idx: number) => (
              <TableCell key={String(idx)} align="center">
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any) => {
            const { displayName: name, userPic, results } = row;
            return (
              <TableRow key={name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" className="align-center" align="center">
                  <img
                    style={{ marginLeft: 6, height: 44, width: 44, borderRadius: 22, alignSelf: 'center' }}
                    src={userPic ? userPic : '/favicon.png'}
                    alt={`${name}-pic`}
                  />
                  <p style={{ margin: 0 }}>{name}</p>
                </TableCell>
                {headers.map((result: any, idx: number) => (
                  <TableCell style={{ textAlign: 'center' }} key={String(idx)} align="right">
                    {results[idx]}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
